<template>
    <div class="main">
        <div class="head">
            <div class="header-center">
                <div class="header-title">
                    源起蔚来
                </div>
                <div class="header-img"></div>
            </div>
        </div>
        <div class="login">
            <div class="login-img">
                <div></div>
            </div>
            <div class="form">
                <van-form @submit="login">
                    <van-field v-model="loginData.userName"
                               name="用户名"
                               label="用户名"
                               placeholder="用户名"
                               :rules="[{ required: true, message: '请填写用户名' }]" />
                    <van-field v-model="loginData.pwd"
                               type="password"
                               name="密码"
                               label="密码"
                               placeholder="密码"
                               :rules="[{ required: true, message: '请填写密码' }]" />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
            </div>

        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import { Toast } from 'vant';

    Vue.use(Toast);
    export default {
        components: {
            //    WinTitle
        },
        name: 'Login',
        data() {
            return {
                loginData: {
                    userName: '',
                    pwd: '',
                }
            };
        },
        methods: {
            login() {
                this.$post(this.apiUrl.loginUrl, this.loginData).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        sessionStorage.setItem('userInfo', JSON.stringify(d.Data));
                        sessionStorage.setItem('token', d.Data.token);
                        this.$router.push({ path: '/app' });
                        Toast.success('登录成功!');
                        Vue.prototype.user = d.Data;

                    }
                });
            },
        },
        created() {

            var openid = this.$route.query.openid;
            if (openid) {
                this.$post(this.apiUrl.loginUrl, { openid }).then((d) => {
                    if (d.IsError) {
                        //Toast.fail(d.ErrorMessage);
                    } else {
                        sessionStorage.setItem('userInfo', JSON.stringify(d.Data));
                        sessionStorage.setItem('token', d.Data.token);
                        this.$router.push({ path: '/app' });
                        Toast.success('自动登录成功!');
                        Vue.prototype.user = d.Data;

                    }
                });
            }
            window.$vue = this;
        }
    }
</script>

<style lang="scss" scoped>

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .main {
        margin: 0px auto;
        background: url(../assets/bj.jpg) no-repeat;
        background-size: cover;
        height: 100%;
        color: #fff;
    }

    .head {
        width: 100%;
        height: 80px;
    }

    .header-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 80px;
        text-align: center;
    }

    .header-center {
        width: 960px;
        height: 100%;
        margin: 0px auto;
        position: relative;
    }

    .header-img {
        background: url(../assets/head.gif) no-repeat center center;
        background-size: 100%;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50px;
    }

    .login {
        width: 960px;
        height: 100%;
        margin: 0px auto;
        position: relative;
        margin-top: 60px;
    }

    .login-img {
        width: 450px;
        height: 450px;
        float: left;
        animation: rotation 15s linear infinite;
    }

    .login-img div {
        background: url(../assets/d.png) no-repeat center center;
        background-size: 100%;
        height: 100%;
        width: 100%;
    }

    .loginForm {
        width: 350px;
        float: right;
        padding: 120px 50px 80px 40px;
        background: url("../assets/e.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;

        .van-cell {
            background-color: transparent;
        }
    }

    .van-cell {
        background-color: transparent;
    }

    ::v-deep.van-form {
        .van-field__control {
            color: #fff;
        }
    }


    @media (max-width:1024px) {

        .header-center, .login {
            width: 100%;
        }

        .login-img {
            width: 100%;
            height: 180px;
        }

        .login-img div {
            width: 180px;
            margin: auto;
        }

        .login {
            margin-top: 30px;
        }

        .form {
            padding: 20px;
        }

        .loginForm {
            margin-top: 180px;
            width: auto;
            float: none;
            background: url("../assets/e.png") no-repeat;
            background-size: 100%;
            text-align: center;
        }
    }
</style>
